
import { defineComponent } from "vue";
import MainCoreValuesAnalyzer from "@/components/MainCoreValuesAnalyzer.vue";

export default defineComponent({
  name: "MyCoreValuesAnalyzer",
  tableData: [],
  components: {
    MainCoreValuesAnalyzer,
  },
  data() {
    return {
      // tableData: [],
    };
  },
  methods: {},
});
